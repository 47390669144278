/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * sofia-pro:
 *   - http://typekit.com/eulas/0000000000000000000157ad
 *   - http://typekit.com/eulas/0000000000000000000157b1
 *   - http://typekit.com/eulas/0000000000000000000157b3
 *
 * © 2009-2019 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2017-11-30 11:05:04 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=lck0ocu&ht=tk&f=24543.24547.24549&a=930642&app=typekit&e=css");

@font-face {
  font-family: "sofia-pro";
  src: url("https://use.typekit.net/af/07553a/0000000000000000000157ad/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/07553a/0000000000000000000157ad/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/07553a/0000000000000000000157ad/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("opentype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "sofia-pro";
  src: url("https://use.typekit.net/af/c07bfb/0000000000000000000157b1/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/c07bfb/0000000000000000000157b1/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/c07bfb/0000000000000000000157b1/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "sofia-pro";
  src: url("https://use.typekit.net/af/83e419/0000000000000000000157b3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/83e419/0000000000000000000157b3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("woff"),
    url("https://use.typekit.net/af/83e419/0000000000000000000157b3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("opentype");
  font-style: normal;
  font-weight: 600;
}
